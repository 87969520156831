import { $t } from '@/localization';
import type { AnalyticEvent, AvailabilityItem, ProjectItem } from '@/shared/types';

type Availability = Pick<AvailabilityItem, 'availability_id' | 'is_favorite' | 'type'>;
type Project = Pick<ProjectItem, 'id' | 'is_favorite'> & { slug?: string };

export function useFavorite() {
  const { $analytics } = useNuxtApp();
  const { isAuth, showAuthModal } = useAuth();
  const { addToast } = useToasts();
  const { reloadCache } = useApiCacheControllerStore();

  const loading = shallowRef<boolean>(false);

  const setFavoriteProject = async (id: number) => {
    const { data } = await useApi<{ success: boolean }>(
      '/projects/favorite',
      {
        body: { project_id: id },
        method: 'post',
      },
    );

    reloadCache('projects');

    return data.value;
  };

  const removeFavoriteProject = async (id: number) => {
    const { data } = await useApi<{ success: boolean }>(
      `/projects/favorite/${id}`,
      { method: 'delete' },
    );

    reloadCache('projects');

    return data.value;
  };

  const likeProject = async (project: Project, isMobile?: boolean): Promise<void> => {
    if (isAuth.value) {
      if (loading.value) {
        return;
      }

      loading.value = true;
      try {
        if (project.is_favorite) {
          const value = await removeFavoriteProject(project.id);
          project.is_favorite = !value.success;

          addToast({ label: $t('favorite-removed') });
          analyticsProject('user_delete_project_from_favorites', project);
        } else {
          const value = await setFavoriteProject(project.id);
          project.is_favorite = value.success;

          addToast({ label: $t('favorite-added') });
          analyticsProject('user_add_project_to_favorites', project);
        }
      } catch (e) {
        console.error('Error liking project:', e);
      } finally {
        loading.value = false;
      }
    } else {
      analyticsProject('guest_try_add_project_to_favorites', project);
      showAuthModal(isMobile);
    }
  };

  const setFavoriteAvailability = async (id: number) => {
    const { data } = await useApi<{ success: boolean }>(
      '/listings/favorite',
      {
        body: { listing_id: id },
        method: 'post',
      },
    );

    reloadCache('listings');

    return data.value;
  };

  const removeFavoriteAvailability = async (id: number) => {
    const { data } = await useApi<{ success: boolean }>(
      `/listings/favorite/${id}`,
      { method: 'delete' },
    );

    reloadCache('listings');

    return data.value;
  };

  const likeAvailability = async (availability: Availability, isMobile?: boolean): Promise<void> => {
    if (isAuth.value) {
      if (loading.value) {
        return;
      }

      loading.value = true;
      try {
        if (availability.is_favorite) {
          const value = await removeFavoriteAvailability(availability.availability_id);
          availability.is_favorite = !value.success;

          addToast({ label: $t('favorite-removed') });
          analyticsAvailability('user_delete_listing_from_favorites', availability);
        } else {
          const value = await setFavoriteAvailability(availability.availability_id);
          availability.is_favorite = value.success;

          addToast({ label: $t('favorite-added') });
          analyticsAvailability('user_add_listing_to_favorites', availability);
        }
      } catch (e) {
        console.error('Error liking project:', e);
      } finally {
        loading.value = false;
      }
    } else {
      analyticsAvailability('guest_try_add_listing_to_favorites', availability);
      showAuthModal(isMobile);
    }
  };

  function analyticsProject(event: AnalyticEvent, project: Project) {
    $analytics.trackEvent(event, 'click', {
      project_id: project.id, // ID проекта (project)
      project_slug: project?.slug, // slug (название) проекта (project)
    });
  }

  function analyticsAvailability(event: AnalyticEvent, availability: Availability) {
    $analytics.trackEvent(event, 'click', {
      listing_id: availability.availability_id, // ID листинга
      property_type: availability.type, // Тип проекта
    });
  }

  return {
    likeProject,
    likeAvailability,
    removeFavoriteProject,
    removeFavoriteAvailability,
    analyticsProject,
    analyticsAvailability,
  };
}
